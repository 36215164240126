//****************************************************
// BUG SNAG CONFIGURATION
//****************************************************

g.bugSnagApiKey = "cd090ba43cfda30e7b0baf2befd88bec";
g.bugSnagNotifyReleaseStages = "production,staging";
g.bugSnagReleaseStage = "development";

// redefine the notifyReleaseStages from a list to an array
g.bugSnagNotifyReleaseStages = g.bugSnagNotifyReleaseStages.split(",");

// dynamic environment detection for determining
// bugsnag release stage if its not defined in the appconfig.json
if ( g.bugSnagNotifyReleaseStages.indexOf(g.bugSnagReleaseStage ) == -1 ){
	if ( g.hostname.indexOf(".com") > -1 )
		g.bugSnagReleaseStage = "production";
	else if ( g.hostname.indexOf(".help") > -1 || g.hostname.indexOf(".tech") > -1  )
		g.bugSnagReleaseStage = "staging";
	else
		g.bugSnagReleaseStage = "development";
}

Bugsnag.start({
	apiKey: g.bugSnagApiKey,
	enabledReleaseStages: g.bugSnagNotifyReleaseStages,
	releaseStage: g.bugSnagReleaseStage
});

console.group("Bugsnag Configuration");
console.log("NotifyReleaseStages: " + g.bugSnagNotifyReleaseStages.join() );
console.log("ReleaseStage: " + g.bugSnagReleaseStage );
console.groupEnd();