
// bring in cookie
var languageSetInCookie = $.cookie("USERLOCALE");

// set default language obj reference
language_chosen = lang_en;

// update set language obj reference
if (languageSetInCookie === null || languageSetInCookie === "en_US") {
    language_chosen = lang_en;
} else {
    language_chosen = lang_es;
}

/**
 * Get a translation (via CF)
 * @param {String} key - mostly the same as CF, e.g. "UIMessages.Record.SavedWithHrs"
 * @param {*} args - numbers need to be set in square brackets, e.g. [0.033] 
 * @returns translated string
 */
function getTranslation(key,args=[]) {
    if (language_chosen[key] === "undefined") {
        Bugsnag.notify("JS i18n key missing: " + key);
        return "xxx i18n xxx";
    }
    else {
        var val = language_chosen[key];
        // replace any {1}, {2}, {3}... placeholders in the string with values from the args array
        if ( args.length > 0 ){
        	for( var i=0; i < args.length; i++ ){
                var placeHolder = "{" + ( i+1 ) + "}";
                val = val.replace(placeHolder, args[i]);
           }
        }
        
        var debug = $.cookie('LOCALEDEBUG');
        return (debug === "true") ? "*** " + val + " ***" : val;
    }
}