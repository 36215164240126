// Load up a few variables we need in several steps
let video;
let canvas = document.querySelector("#canvas");
let videoStream; // current video stream

$(document).ready(function(){
	if (!"mediaDevices" in navigator || !"getUserMedia" in navigator.mediaDevices) {
		$("#btnScreenshotInactive").hide();
		$("#camera-error-message").append(getTranslation("JavaScript.Camera.ClockPhotoCameraProblem"));
		$("#camera-problem").show();
		return;
	}
	else {
		video = document.querySelector("#video");	// html <video> tag
		canvas = document.querySelector("#canvas");
	}
	// Camera button in ClockInOutModal.cfm
	$("#btnScreenshot").on("click", function(){
		takeSnapshot();
	});

	// Intercept the Enter key and force the user to click the "Take Photo" button (Chrome bug!)
	$(document).on("keyup", function(event){
		if(event.key == "Enter"){
			event.preventDefault();
			event.stopPropagation();
		}
	});
});

/**
 * Close the modal with the cancel button/link
 */
function cancelSnapshot(){
	closeSnapshotModal();
}

/**
 * Take a snapshot of the user with the system camera inside a modal
*/
function takeSnapshot(){
	$("#btnScreenshot").hide();		// no need to keep showing the buttons now that we're underway
	$("#cancelBtn").hide();
	$(".close").hide();
	$("#snapshot-mask").hide();
	$("#screenshots").empty();	// clean up any previous snapshots
	$(".status").append(getTranslation("JavaScript.Camera.ProcessingClockPhoto"));
	$(".status").show();

	const img = document.createElement("img");
	canvas.width = video.videoWidth;
	canvas.height = video.videoHeight;
	canvas.getContext("2d").drawImage(video, 0, 0);
	img.src = canvas.toDataURL("image/jpeg", 0.5);	// 2nd arg is quality, which can go from 0 to 1.0
	// console.log("takeSnapshot() img.src = ", img.src);

	$("#video").hide();

	// show the captured imaged if the src is greater than 6 ie 'data;,' meaning there is probably a valid image
	if ( img.src.length > 6 ){
		$("#screenshots").show();
		$("#screenshots").prepend(img);
	}

	let imageData = img.src.replace("data:image/jpeg;base64,","");
	emitUpdatePhotoData(imageData);	// updates the Clock In/Out modal in ***.js 		... change to emit when we go to vuejs!
	stopVideoStream();
}

/**
* Stop the video stream to turn the user's camera off 
*/
function stopVideoStream() {
	if (videoStream) {
		videoStream.getTracks().forEach((track) => {
		 	track.stop();
		});
	}
}

/**
 * Put a red square in the center of the camera view so people can center their faces
 * 	Just a guide, has no effect on anything
 */
function showCameraMask(){
	$("#snapshot-mask").hide();
	let vWidth = $("#video").width();
	let vHeight = $("#video").height();
	let maskWidth = $("#snapshot-mask").width();
	let marginLeft = (vWidth / 2) - (maskWidth / 2);
	let marginTop = (vHeight / 2) - (maskWidth / 2);
	$("#snapshot-mask").css("margin-left",marginLeft);
	$("#snapshot-mask").css("margin-top",marginTop);
	$("#snapshot-mask").fadeIn();
}

/**
* Initialize the camera/videostream, show buttons/controls
*/
async function initializeCamera() {
	// console.log("initializeCamera()");
	$("#btnScreenshot").hide();
	$("#cancelBtn").show();
	$(".close").show();
	stopVideoStream();
	try {
		// video constraints
		const constraints = { video: {}};	// some browsers require this, but FF won't allow sizing here, fyi
		videoStream = await navigator.mediaDevices.getUserMedia(constraints);
		video.srcObject = videoStream;
		$("#btnScreenshotInactive").hide();
		$("#btnScreenshotActive").show();
		$("#cameraLoading").hide();
		$("#snapshotContainer").show();

		video.onloadeddata = function(){
			// don't show the camera button until the video stream is ready
			$("#btnScreenshot").show();
			$("#snapshotContainer").focus();
		};

		// wait a second before loading the mask to get the final size of the video
		setTimeout(function(){
			showCameraMask();
		}, 1000)

	}
	catch (err) {
		$("#cameraLoading").hide();
		$("#snapshotContainer").show();
		$("#camera-error-message").append(getTranslation("JavaScript.Camera.ClockPhotoCameraProblem"));
		$("#btnScreenshotInactive").hide();
		$("#camera-problem").show();
	}
}

/**
* Close the modal and update & clean up the contents
*/
function closeSnapshotModal(){ 
	$("#screenshots").hide();
	$("#snapshot-mask").hide();
	stopVideoStream();
	$("#video").empty();
	$("#video").show();
	$(".status").hide();
	$(".status").empty();
	$("#camera-problem").hide();
	$("#edit-shift").hide();
	$("#clock-out-choice").show();
	// $("#tsModal").hide();
	closeTSModalById("clock-in-out-modal");	// ~ emit in vue 
}

/**
*	Depricated: X will not show, but leaving here in case it comes back 
* 	When the user clicks on <span> (x), close the modal
*/
function closeSnapshotModal_OLD(){
	closeModal();
}

var modal = document.getElementById("tsModal");
/**
* Close the modal when the user clicks anywhere outside it - not currently activated!
*/
/* 
window.onclick = function(event) {
	if (event.target == modal) {
		// closeModal();
	}
}
*/

/**
 * Launch the snapshot modal, then initialize the camera
 */
function snapshotLaunch(){
	// $("#tsModal").show();
	$("#btnScreenshotInactive").show();
	$("#btnScreenshotActive").hide();
	$("#cameraLoading").show();
	$("#snapshotContainer").hide();
	initializeCamera();
}
