/* ------------------------------------------ Start ------------------------------ */

// modify ajax and requests
$.ajaxSetup({
	cache: false
});

/* ------------------------------------------ Ajax Globals ------------------------------ */

// injects missing tokens / logout removed
$(document).ajaxSuccess(function(e, xhr, settings) {
	var o;
	if (typeof xhr.getResponseHeader("Content-Type") != "undefined"
		&& typeof xhr.responseText !== "undefined"
		&& xhr.responseText !== ""
		&& xhr.getResponseHeader("Content-Type").indexOf("application/json") != -1) {
		o = JSON.parse(xhr.responseText)
	}
	initInjectMissingTokens();
});

// called everywhere
function processAjaxError(jqXHR, textStatus, errorThrown) {
	console.log("Ajax error -----------------------------------");
	console.log("textStatus: " + textStatus);
	console.log("errorThrown: " + errorThrown);
	console.dir(jqXHR);
	console.log("----------------------------------------------");

	let errorTitle = getTranslation("JavaScript.Title.Attention");
	let msgProblem = getTranslation("JavaScript.ProblemCommunicatingWithServer");
	let msgTryAgain = getTranslation("JavaScript.PleaseTryAgain");
	let ajaxErrorModal = new TSModal("ajaxErrorModal", { title: errorTitle, content: '<p>'+msgProblem+'</p><p>'+msgTryAgain+'</p>' });
	closeTSModal();
	ajaxErrorModal.launch();
}

/* ------------------------------------------ Ajax Init ------------------------------ */

// Setup from #pageWrapper
// Provides live page updates for the page that you're on
function initAjaxIntervalUpdates() {
	var config = new Array();

	if ( !$("#pageWrapper").is(".external") ) {

		config.push("NOTIFICATIONS");

		// #Clock
		if ($("#clock").length > 0) {
			config.push("CURRENTLOCALTIME");
			GetAjaxCurrentLocalTime();
		}

		// #ClockOut
		config.push("USERONCLOCK");
		// // we don't need to update the left nav in kiosk mode
		if($("#kioskEnabled").length == 0 && $("#kioskEnabled").val() != "true"){
			GetAjaxCurrentShiftDetails();
		}

		// #MessageCenter
		if ($("#MessageCenterMsgTextBox").length > 0 || $("#MessageCenter").length > 0){
			config.push("MESSAGECENTER")
			GetAjaxMessageCenterCount();
		}

		// #Timer
		if ($("div.UpdateHoursTimer").length > 0) {
			GetProjectTimers();
		}

		// set to Global Scope
		g.ajaxUpdateObj.itemsRequested = config;

		// update interval
		g.ajaxUpdateObj.interval = setInterval( ajaxIntervalUpdate, g.ajaxUpdateObj.ms );

		// set timeout for window
		setTimeout(function(){
			$(window)
				.on("blur.ajaxIntervalUpdate",function(){
					g.windowIsFocused = false;
				})
				.on("focus.ajaxIntervalUpdate",function(){
					if (!g.windowIsFocused) {
						ajaxIntervalUpdate();
						g.windowIsFocused = true;
					}
				})
				.on("unload.ajaxIntervalUpdate",function(){
					clearInterval(g.ajaxUpdateObj.interval);
				});
		}, 1000);
	}
}

/* ------------------ Ajax Re-Calls to Live Page ------------------ */

// Based upon the interval re-up
function ajaxIntervalUpdate() {
	// console.log("%c ajaxIntervalUpdate() g = ","color:blue;border:solid 1px orange", g);
	var ajaxRequestArray = g.ajaxUpdateObj.itemsRequested;
	var ajaxTimersArray = g.ajaxUpdateObj.projectTimers;

	if (ajaxRequestArray.indexOf("CURRENTLOCALTIME") !== -1) {
		GetAjaxCurrentLocalTime();
	}

	// always on for clocked in and clocked out
	GetAjaxCurrentShiftDetails();

	if (ajaxRequestArray.indexOf("MESSAGECENTER") !== -1) {
		GetAjaxMessageCenterCount();
	}

	if ( ajaxTimersArray && ajaxTimersArray.length ) {
		GetAjaxProjectTimers();
	}
}


/* ------------------ Ajax Calls --------------------------------- */

// GetAjaxCurrentLocalTime
function GetAjaxCurrentLocalTime() {
	var url = g.baseApiUrl + '/server/time';
	$.ajax({
		url: url,
		type: 'GET',
		cache: false,
		dataType: 'json',
		headers: createBaseApiHeaders(getApiKeyFromStorage(),getApiTokenFromStorage()),
		success: function( data ) {
			// Example Response: {S: "10", H: "16", M: "8"}
			updateClock( data.USERTIME );
		}
	});
}

function GetAjaxCurrentShiftDetails() {
	var url = g.baseApiUrl + '/clock/shift/details';
	GetCurrentShiftDetailsPromise()
		.then((shiftData) => {
			// console.log("%c NEW! now in ajax general, GetAjaxCurrentShiftDetails(), promise data: ", "background-color:pink;", shiftData);
			if (shiftData.Status == 'Clocked-In'){
				// Call and clear updateHrsWorkedToday
				clearInterval( g.hrsWorkedTodayInt );
				// we don't need to update the left nav in kiosk mode
				if($("#kioskEnabled").length == 0 && $("#kioskEnabled").val() != "true"){
					g.hrsWorkedTodayInt = setInterval( processNavClockData(shiftData), 60000 );
				}
			}
			else if ($("input[name=IdempotencyToken]").length > 0 && shiftData.Status == 'Clocked-Out' && ($("#kioskEnabled").length == 0 && $("#kioskEnabled").val() != "true") && $("#sidemenu").length){
				processNavClockData(shiftData);
			}			
		})
		.catch((error) => {
			console.error(error);
		});
}

function GetCurrentShiftDetailsPromise(){
	return new Promise((resolve, reject) => {
		$.ajax({
			url: g.baseApiUrl + '/clock/shift/details',
			type: 'GET',
			cache: false,
			dataType: 'json',
			headers: createBaseApiHeaders(getApiKeyFromStorage(),getApiTokenFromStorage()),
		})
		.done(function(shiftData){
			//console.log("%cGetCurrentShiftDetailsPromise(), shiftData = ", "color: green;background-color:#FFFFCC;", shiftData);
			resolve(shiftData);
		})
		.fail(function(error){
			console.error(error);
			reject(error);
		})
	})
}

function GetAjaxMessageCenterCount() {
	var url = g.baseApiUrl + '/messagecenter/count';

	$.ajax({
		url: url,
		type: 'GET',
		cache: false,
		dataType: 'json',
		headers: createBaseApiHeaders(getApiKeyFromStorage(),getApiTokenFromStorage()),
		success: function( data ) {
			// console.log("GetAjaxMessageCenterCount(), ajax, data: ", data);
			if ( data.TotalMessages > 0 ){
				let maxMessages = 9;			// largest number of messages to show
				let maxMessagesText = "9+";		// what the user sees
				// Total message count
				var displayCount = data.TotalMessages;
				if(displayCount > 9){
					displayCount = maxMessagesText;	// if this increases we'll have to adjust the height and width of #MessageCenterNewMsgCount in _Framework.scss
				}
				$(".MessageCenterNewMsgCount").show();
				$(".MessageCenterNewMsgCount").html(displayCount).removeClass("empty");
				$(".MessageCenterNewMsgCount").html(displayCount).addClass("hasNotification");

				// Notifications
				if(parseInt(data.UnreadNotifications) > maxMessages){
					$("#NotificationsCount").removeClass("empty").html(maxMessagesText);
				}
				else if(parseInt(data.UnreadNotifications) != 0){
					$("#NotificationsCount").removeClass("empty").html(data.UnreadNotifications);
				}
				else {
					$("#NotificationsCount").addClass("empty");
				}

				// Account Alerts
				if(parseInt(data.UnreadAlerts) > maxMessages){
					$("#AccountAlertsCount").removeClass("empty").html(maxMessagesText);
				}
				else if(parseInt(data.UnreadAlerts) != 0){
					$("#AccountAlertsCount").removeClass("empty").html(data.UnreadAlerts);
				}
				else {
					$("#AccountAlertsCount").addClass("empty");
				}

				$("#messageCenterIcon").empty().append('<i class="fa fad fa-2x fa-megaphone toggleIcon"></i>');
				$("#messageCenterLeftNavIcon").empty().append('<i class="fa fas fa-megaphone"></i>');

				// Memos
				if(parseInt(data.UnreadMemos) > maxMessages){
					$("#MemoCount").removeClass("empty").html(maxMessagesText);
				}
				else if(parseInt(data.UnreadMemos) != 0){
					$("#MemoCount").removeClass("empty").html(data.UnreadMemos);
				}
				else {
					$("#MemoCount").addClass("empty");
				}

				// New messages
				if(parseInt(data.NewMessages) > maxMessages){
					$("#NewMessages").removeClass("empty").html(maxMessagesText);
				}
				else if(parseInt(data.NewMessages) != 0){
					$("#NewMessages").removeClass("empty").html(data.NewMessages);
				}
				else {
					$("#NewMessages").addClass("empty");
				}

			}
			else {
				$(".MessageCenterNewMsgCount").html("");
				$(".MessageCenterNewMsgCount").removeClass("hasNotification");
				$(".MessageCenterNewMsgCount").addClass("empty");
				$("#messageCenterIcon").empty().append('<i class="fa fad fa-2x fa-megaphone toggleIcon"></i>');
				$("#messageCenterLeftNavIcon").empty().append('<i class="fa fas fa-megaphone"></i>');
			}

		}
	});
}

// GetAjaxProjectTimers
function GetAjaxProjectTimers() {

	var url = g.baseApiUrl + '/project/timers';

	var formdata = { ProjectTimers: $.toJSON(g.ajaxUpdateObj.projectTimers) };

	$.ajax({
		url: url,
		type: 'POST',
		cache: false,
		data: formdata,
		dataType: 'json',
		headers: createBaseApiHeaders(getApiKeyFromStorage(),getApiTokenFromStorage()),
		success: function( data ) {
			if ( typeof data === "object" ) {
				updateAllProjectTimerHours( data.data );
			}
		}
	});
}

// Gets Translations
// GetTranslations("es_MX", "PageTitles", "Sys.Tools.APIAccessSub");
function GetTranslations(language, page, translation) {
	var url = g.baseApiUrl + '/translation/' + language + '/' + page + '/';

	$.ajax({
		url: url,
		type: 'POST',
		data: {
			"translation" : translation
		},
		cache: false,
		dataType: 'json',
		headers: createBaseApiHeaders(getApiKeyFromStorage(),getApiTokenFromStorage()),
		success: function(data) {
			return(data);
		}
	});
}

// This function is called by GetAjaxProjectTimers()
function updateAllProjectTimerHours( data ) {
	$.each(data, function(i,o){
		var $e = $("tr[data-userid="+o.USERID+"][data-accountcodeid="+o.ACCOUNTCODEID+"][data-projectid="+o.PROJECTID+"][data-customerid="+o.CUSTOMERID+"]");
		if ($e.length > 0)
			$e.find("div.UpdateHoursTimer").fadeOut(250).text(o.RUNNINGHOURS).fadeIn(500);
		else {
			$e.slideUp(2000,function(){
				$(this).remove();
				showHideAllButtons();
			});
		}
	});
}

// Isolated into its own function GETPROJECTIMERS
function GetProjectTimers(){
	g.ajaxUpdateObj.projectTimers = new Array();
	$("div.UpdateHoursTimer").each(function(){
		g.ajaxUpdateObj.projectTimers.push($(this).closest("tr").data());
	});
}

/* ------------------------------------------ Handler Functions ------------------------------ */

// adds class (submitAjaxForm)
// with any form that contains (IsAjax)
// TODO @miles REFACTOR WEEK VIEW I think this is the only place left, remove it
function setAjaxFormsSubmitHandler() {
	$("form:has(input[name=IsAjax][value=true]):not(.submitajaxform)").addClass("submitajaxform")
	$("#pageWrapper").on("submit","form:has(input[name=IsAjax][value=true])",function(ev){
		if (!ev.isDefaultPrevented()) {
			$(this).ajaxSubmit();
			return false;
		}
	})
}

/* ------------------------------------------ Extension Functions ------------------------------ */

// TODO @miles REFACTOR WEEK VIEW REMOVE THIS

// called everywhere
// AJAX ENDPOINT
$.fn.extend({
	ajaxSubmit: function() {
		var dataObj = {};
		dataObj = $(this).serializeJSON();
		$.ajax({
			type: "POST",
			url: $(this).attr("action"),
			data: dataObj,
			dataType: "html",
			cache: false,
			success: function(returnToJS) {
				//ignore:eval
				eval(returnToJS);
			},
			error: function(jqXHR, textStatus, errorThrown){ processAjaxError(jqXHR, textStatus, errorThrown); }
		});
		return false;
	}
});

/* ------------------------------------------ Debug and Errors ------------------------------ */

// debugging filter
$.ajaxPrefilter( function( opts, originalOptions, jqXHR ) {
	if ( opts.data ) {
		if (opts.data.indexOf("&Token") == -1)
			opts.data += "&Token=" + g.csrfToken;
	}

	var originalSuccess;

	if (typeof opts.success !== "undefined") {
		originalSuccess = opts.success;
		opts.success = function (data) {
			var cfError = false;
			if (jqXHR.responseText.indexOf("cfdump_toggleRow") != -1)
				cfError = true;
			if (g.debug && cfError) {
				alertDialog({
					msg: "Loading CF Error...",
					title: "CF Error from Ajax",
					callback: function() {
						$("#dialog")
							.empty()
							.prepend( jqXHR.responseText )
							.find("*")
							.css("position","relative");
					},
					width: "75%",
					height: Math.round($(window).height()*0.75),
					dialogClass: "fixDialog",
					draggable: true,
					resizable: true
				});
			}
			else {
				if (originalSuccess != null)
					originalSuccess(data);
			}
		};
	}
});