/* ------------------------------------------ Ready ------------------------------ */

$(document).ready(function() {

	// global vars
	g.isIE = (getIEVersion() == false) ? false : true;
	g.isIEVerLTE8 = (getIEVersion() != false && getIEVersion() <= 8);
	g.isIOSSafari = isIOSSafari();
	g.isFF = isFF();

	// init functions
	initUTCOffsetCookie();
	initInjectMissingTokens();
	initAjaxIntervalUpdates();
	initComboBox();
	initStylesAnimations();
	initTimeDropDowns();
	initCalendars();
	initSelectAllTables();
	initNotesControls();
	initTableSorting();
	initTooltips();
	initDialog();
	initAutoFormConfirms();
	initButtons();
	initTextAreaExpansion();
	initTableScrolling();
	initPageTop();	// set the fixed top to account for watermark, error, etc.

	// add script tag at body end
	var scr = document.createElement('script');
	scr.text= 'loadAfterBody()';
	document.body.appendChild( scr );

});