/* ------------------------------------------ Main Functions and Init ------------------------------ */

// Initializes TableSorting
// Specific tablesorting for notes (.notes)
// calls initTableSortingFinish()
function initTableSorting(doIt) {
	var autoTableSortMax = 1000;
	var notAppliedMsg;

	if ( typeof doIt === "undefined" )
		doIt = false;

	// not notes
	if ( doIt || $(".tablesorter > tbody > tr:not(.notes)").length < autoTableSortMax) {
		if (doIt) {
			pleaseWait({
				msg:"Enabling table sorting...",
				callback: function(){
					$("#tableSortingNotAppliedMsg").slideUp(250,function(){
						$(this).remove();
					});
				}
			});
		}
		setTimeout( function() {
			initTableSortingFinish();
		}, 10 );
	}
	// notes
	else {
		notAppliedMsg = '<input type="button" type="button" style="float:right;margin:0 0 0.5em 0.5em;" onclick="initTableSorting(true);" value="Enable Table Sorting" />'
						+ '<div style="vertical-align:middle;"><strong>Table Sorting Disabled</strong> - These tables are large, so the table sorting feature was disabled to save you time loading the page. Click the button on the right to enable table sorting.</div>'
						+ '<div class="clear"></div>';

		$(".tablesorter:eq(0)").css("marginTop","0");
		$("<div />")
			.attr( {
				id: "tableSortingNotAppliedMsg",
				"class": "instructions noPrint"
			})
			.html(notAppliedMsg)
			.css({
				padding: "1em",
				marginBottom: "-5px"
			})
		.insertBefore(".tablesorter:eq(0)");
	}
}


// Finalizes TableSorting configuration
// Sets header column sorting type
function initTableSortingFinish() {
	// Info: SortArrayLabels (labels) key-value pair
	// Example: "Acct Code","text"
	// the key "Acct Code" is used only as a reference for positioning
	// the value "text" is the important part

	var showPleaseWaitWhileSorting = $("table.tablesorter > tbody > tr:not(.notes)").length > 200,
			sortList,
			sortingToolTipLink,
			sortingToolTipHTML,
			sortArrayLabels = new Array(
				"false",false,
				"False",false,
				"Status","text",
				"Acct Code","text",
				"Acct. Code","text",
				"Account Code","text",
				"Employee","text",
				"Name","text",
				"Customer","text",
				"Project","text",
				"Vendor","text",
				"Event","text",
				"Break","text",
				"TZ","text",
				"Time Class","text",
				"Work Notes","text",
				"Notes","text",
				"Comments","text",
				"Bill Rate","currency",
				"Pay Rate","currency",
				"Bill Total","currency",
				"Pay Total","currency",
				"Cost Total","currency",
				"BillRate","currency",
				"PayRate","currency",
				"BillTotal","currency",
				"PayTotal","currency",
				"CostTotal","currency",
				"Amount","currency",
				"Expenses","currency",
				"Hours","digit",
				"Mileage","digit",
				"Date","tcoDate",
				"In","tcoTime",
				"Clock In","tcoTime",
				"Out","tcoTime",
				"Clock Out","tcoTime"
	);

	// zebra color widget
	$.tablesorter.defaults.widgets = ['zebra'];

	// loop over all tables
	$(".tablesorter").each(function (i) {

		var $t = $(this);
		var $totalsRow = $t.find("tbody:eq(0) tr:last-child:not(.notes):has(td[colspan])");

		// wrap row in it's own tbody
		if ($totalsRow.length > 0) {
			$t.find( "tbody:eq(0)" ).after( $totalsRow );
			$totalsRow.wrap( "<tbody></tbody>" );
		}
		// give each tablesorter a number
		if (!$t.is( "[id]" )){
			$t.attr( "id", "tablesorterNum" + i );
		}

		// apply proper column sort types based on header text
		for (n=0;n<sortArrayLabels.length;n=n+2) {
			$t.find( "th:not([data-sorter=false])" )
				.filter(function() {
					return $(this).text() == sortArrayLabels[n];
				})

				.data( "sorter", sortArrayLabels[n+1] );
		}

		// remove sort on columns that are not designated with a sorter, or which contain a notes control
		$t.find( "th:not([data-sorter]), th:has(.notesControlAll)" ).data( "sorter", false );

		// remove sort on columns with no column header text
		$t.find("th")
			.filter(function() {
				return ($(this).text() == "" || $.trim($(this).html()) == "&nbsp;");
			})
			.data("sorter",false);

		// add the no-wrap class if reportHeaderWrapping is set to "true"
		if(g.clientSettingsObj.reportHeaderWrapping != "true"){		// note: text, not an actual boolean
			$t.find("th").addClass("noWrapHeading");
		}

		// if it has a tbody and a thead, then apply the tablesorter
		if ($t.find("tbody, thead").length >= 2) {
			$t.tablesorter({});
		}

		
		// Set an overlay with spinner while waiting for content to sort 
		$t.on("sortStart", function(){
			let left = $(this).position().left;
			let top = $(this).position().top;
			let width = $(this).width();
			let height = $(this).height();
			let overlay = '<div class="sort-overlay" id="overlay_'+$(this).id+'" style="width:'+width+'px;height:'+height+'px;left:'+left+'px;top:'+top+'px;"><div><i class="fas fa-spinner fa-spin-pulse fa-2x"></i></div></div>';
			$(this).append(overlay);
		});

		// Remove the overlay when content has been sorted 	
		$t.on("sortEnd", function(){
			$("#overlay_"+$(this).id).remove();
		});
	});
	
	$(".tablesorter-grouped").tablesorter().bind("sortEnd", function() {
		var table = $(this);
		var groups = {};

		// Group rows based on 'data-group' attribute
		// Example usage:
		// <tr data-group="group1" data-primary="true">Top row</tr>
		// <tr data-group="group1">Bottom row</tr>

		table.find('tbody tr').each(function(){
			var group = $(this).data('group');
			if (group) {
				if (!groups[group]) {
					groups[group] = [];
				}

				if ($(this).data('primary')) {
					groups[group].unshift(this);
				} else {
					groups[group].push(this);
				}
			}
		});

		// Rearrange rows to keep grouped rows together
		$.each(groups, function(group, rows){
			for (var i = 1; i < rows.length; i++) {
				$(rows[i]).insertAfter(rows[i-1]);
			}
		});
	});

	// grouped sorting
	$('body').on("click", ".tablesorter thead th", function(e) {
		var thisTableConfig = $(this).closest("table")[0].config.sortList;
		if (g.clientSettingsObj.tableSorting === "grouped") {
			$('.tablesorter').trigger("sorton", [thisTableConfig]);
		}
	});

	// setup promise to close dialog when done sorting
	if ($("table.tablesorter").length > 0) {
		$("table.tablesorter").promise().done(function(){
			// Was checking to see if there is an actual instance of the dialog box before trying to close (this was throwing an exception on page load), but seems fine so commenting out trap:
			// if ($("table.tablesorter").dialog("instance")){}
			closeDialog();
		});
	}

	sortingToolTipHTML = "<ul><li>" + getTranslation("JavaScript.ToolTip.TableSortListOne") + "</li><li>" + getTranslation("JavaScript.ToolTip.TableSortListTwo") + "</li></ul>";

	dialogSettings = {
		title: getTranslation("JavaScript.ToolTip.TableSortLabel"),
		msg: sortingToolTipHTML
	};

  	sortingToolTipLink = "<div style='float:right;padding-right:3px;'><a href='javascript:void(0);' class='noPrint' onclick='infoDialog(" + JSON.stringify(dialogSettings) + ");'><i class='fas font-charcoal fa-lg fa-comment-lines' aria-hidden='true'></i></a></div>";

	// prepend caption to each table with hint which uses the tooltip
	$("table.tablesorter > caption").prepend(sortingToolTipLink);
}


/* ------------------------------------------ General Use Functions ------------------------------ */

// called from initTableSorting()
function hideTablesorterPleaseWait() {
	clearInterval(g.tablesorterWaitInt);
	closeDialog();
}

// called from from multiple sections
// toggles class (.even)
function setZebraStripes(allFlag) {
	var altRows = ".notes, .payrollTableHeader, .smallHeaders",
			nots = "[data-alternated], .alternateRowsByDate, .skipRowHighlight",
			$tables = $(".dataTable, .smallData, .tablesorter");

	if (typeof allFlag === "undefined") {
		nots = ":hidden," + nots;
		$tables = $tables.filter(":almostonscreen")
	}

	$tables
		.not(nots)
		.each(function() {
			var $filtered, $filteredb;
			$filtered = $(this).children("tbody:eq(0)").children("tr:has(td)").not(altRows)
			$filtered
				.filter(":even")
				.toggleClass("odd", true)
				.end()
				.filter(":odd")
				.toggleClass("even", true);

			$filteredb = $(this).children("tbody:eq(0)").children("tr:has(td)").not(".skipRowHighlight").filter(altRows);

			$filteredb
				.each(function(){
					$(this).toggleClass("even",$(this).prev("tr:has(td)").is(".even"));
				});

			$(this).attr("data-alternated","true");
		});
}

/* ------------------------------------------ TableSorter Parsers ------------------------------ */

// Parser to strip text out of nested tags in a td
$.tablesorter.addParser({
	id: 'stripText',
	is: function(s) {
		return false;
	},
	format: function(s) {
		s = s.replace(/'/g, '');
		return $.trim($(s).text().toLowerCase());
	},
	type: "text"
});

// Parser for date formats: Jan 06, 10 or Jan 06, 2010
$.tablesorter.addParser({
	id: 'tcoDate',
	is: function(s) {
		return false;
	},
	format: function(s) {
		var date = s.match(/^(\w{3})[ ](\d{1,2}),[ ](\d{2,4})$/),
				monthNames = {"Jan":"01","Feb":"02","Mar":"03","Apr":"04","May":"05","Jun":"06","Jul":"07","Aug":"08","Sep":"09","Oct":"10","Nov":"11","Dec":"12"},
				m = monthNames[date[1]],
				d = String(date[2]),
				y;

		if (d.length == 1)
			d = "0" + d;
		y = date[3];

		return '' + y + m + d;
	},
	type: 'numeric'
});

// Parser for tco time format
$.tablesorter.addParser({
	id: "tcoTime",
	is: function(s) {
		return false;
	},
	format: function(s) {
		if (s.match(/AM|PM/g) == null)
			s = s.replace("A","AM").replace("P","PM");
		return $.tablesorter.formatFloat(new Date("2000/01/01 " + s).getTime());
	},
	type: "numeric"
});

// Parser for date type
$.tablesorter.addParser({
	id: 'date',
	is: function(s) {
		return false;
	},
	format: function(s) {
		var d = Date.parse(s);
		if (isNaN(d)) {
			return -1;
		}
		return d;
	},
	type: 'numeric'
});


/**
 * Gets all the sortable columns in reports and adds a listener to set sort order and direction for printing
 */
$(document).ready(function(){
	// Get all the report table columns
	let cols = $("table.tablesorter tr th");
	let tableSortOrder = "";
	let sortDirection = "ASC";

	for(var i = 0; i < cols.length; i++){
		if($(cols[i]).attr("data-sorter")){
			// add a listener to each sortable column
			$(cols[i]).on("click", function(event){

				console.log("Initial TableSortOrder = ", tableSortOrder);

				let dataset = JSON.parse(JSON.stringify(event.currentTarget.dataset));
				sortDirection = (event.currentTarget.ariaSort == "ascending") ? "DESC" : "ASC";
				if(event.shiftKey){
					tableSortOrder = setMultipleSortingValues(tableSortOrder,dataset.sortkey, sortDirection);
				}
				else {
					tableSortOrder = dataset.sortkey + "." + sortDirection;
				}
				console.log("Updated TableSortOrder = ", tableSortOrder);
				$("#TableSortOrder").val(tableSortOrder);

			});
		}
	}

	/*
	*	Sets for order and direction when we sort by multiple columns
	*	@param {String} newOrderBy - column key for sorting
	*	@param {String} newDirction - ASC or DESC
	*/
	function setMultipleSortingValues(currentSortOrder, newOrderBy, newDirection){
		//console.log("setMultipleSortingValues(currentSortOrder,newOrderBy, newDirection): ", currentSortOrder, newOrderBy, newDirection);

		// if the column to be added was already in the sort order list, remove it
		if(currentSortOrder.includes(newOrderBy + ".ASC")){
			currentSortOrder = currentSortOrder.replace((newOrderBy + ".ASC"), "")
		}
		else if(tableSortOrder.includes(newOrderBy + ".DESC")){
			currentSortOrder = currentSortOrder.replace((newOrderBy + ".DESC"), "")
		}

		// replace any doubled delimiter left from replace
		currentSortOrder = currentSortOrder.replace("__", "_");
    // remove any trailing delimiter if it was the last element that was removed
		if ( currentSortOrder.substr(-1) === "_" )
			currentSortOrder = currentSortOrder.slice(0, -1); // Remove the last character
    // remove any leading delimiter if it was the first element that was removed
		if ( currentSortOrder.substr(0,1) === "_" )
			currentSortOrder = currentSortOrder.slice(1); // Remove the first character

		// append the new column to the end of the list
    if ( currentSortOrder === '' )
			currentSortOrder = newOrderBy + "." + newDirection;
    else
			currentSortOrder = currentSortOrder + "_" + newOrderBy + "." + newDirection;

		return currentSortOrder;

	}

});
